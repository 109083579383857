import { FC, useState } from 'react';
import CartItem from '@components/cart/CartItem';
import SidebarLayout from '@components/common/SidebarLayout';
import { Button, Text } from '@components/ui';
import { useUI } from '@components/ui/context';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';

import useCart from '@vercel/commerce-shopify/cart/use-cart';
import useCheckout from '@vercel/commerce-shopify/checkout/use-checkout';
import usePrice from '@vercel/commerce-shopify/product/use-price';
import { useCheckoutContext } from '../context';
import PaymentWidget from '../PaymentWidget';
import ShippingWidget from '../ShippingWidget';
import s from './CheckoutSidebarView.module.css';

const CheckoutSidebarView: FC = () => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { setSidebarView, closeSidebar } = useUI();
  const { data: cartData, mutate: refreshCart } = useCart();
  const { data: checkoutData, submit: onCheckout } = useCheckout();
  const { clearCheckoutFields } = useCheckoutContext();
  const { t } = useTranslation();

  async function handleSubmit(event: React.ChangeEvent<HTMLFormElement>) {
    try {
      setLoadingSubmit(true);
      event.preventDefault();

      await onCheckout();
      clearCheckoutFields();
      setLoadingSubmit(false);
      refreshCart();
      closeSidebar();
    } catch {
      // TODO - handle error UI here.
      setLoadingSubmit(false);
    }
  }

  const { price: subTotal } = usePrice(
    cartData && {
      amount: Number(cartData.subtotalPrice),
      currencyCode: cartData.currency.code,
    }
  );
  const { price: total } = usePrice(
    cartData && {
      amount: Number(cartData.totalPrice),
      currencyCode: cartData.currency.code,
    }
  );

  return (
    <SidebarLayout
      className={s.root}
      handleBack={() => setSidebarView('CART_VIEW')}
    >
      <div className='px-4 sm:px-6 flex-1'>
        <Link href='/cart'>
          <a>
            <Text variant='sectionHeading'>{t('checkout.title')}</Text>
          </a>
        </Link>

        <PaymentWidget
          isValid={checkoutData?.hasPayment}
          onClick={() => setSidebarView('PAYMENT_VIEW')}
        />
        <ShippingWidget
          isValid={checkoutData?.hasShipping}
          onClick={() => setSidebarView('SHIPPING_VIEW')}
        />

        <ul className={s.lineItemsList}>
          {cartData!.lineItems.map((item: any) => (
            <CartItem
              key={item.id}
              item={item}
              currencyCode={cartData!.currency.code}
              variant='display'
            />
          ))}
        </ul>
      </div>

      <form
        onSubmit={handleSubmit}
        className='flex-shrink-0 px-6 py-6 sm:px-6 sticky z-20 bottom-0 w-full right-0 left-0 bg-accent-0 border-t text-sm'
      >
        <ul className='pb-2'>
          <li className='flex justify-between py-1'>
            <span>{t('cart.subtotal')}</span>
            <span>{subTotal}</span>
          </li>
          <li className='flex justify-between py-1'>
            <span>{t('cart.taxes')}</span>
            <span>{t('cart.calculated_at_checkout')}</span>
          </li>
          <li className='flex justify-between py-1'>
            <span>{t('cart.shipping')}</span>
            <span className='font-bold tracking-wide'>
              {t('cart.shipping_free')}
            </span>
          </li>
        </ul>
        <div className='flex justify-between border-t border-accent-2 py-3 font-bold mb-2'>
          <span>{t('cart.total')}</span>
          <span>{total}</span>
        </div>
        <div>
          {/* Once data is correctly filled */}
          <Button
            type='submit'
            width='100%'
            disabled={!checkoutData?.hasPayment || !checkoutData?.hasShipping}
            loading={loadingSubmit}
          >
            {t('cart.confirm')}
          </Button>
        </div>
      </form>
    </SidebarLayout>
  );
};

export default CheckoutSidebarView;
