import React, { useCallback, useMemo } from 'react';

export enum ModalView {
  SignUp = 'SIGNUP_VIEW',
  Login = 'LOGIN_VIEW',
  Forgot = 'FORGOT_VIEW',
  NewShippingAddress = 'NEW_SHIPPING_ADDRESS',
  NewPaymentMethod = 'NEW_PAYMENT_METHOD',
}

export enum SidebarView {
  Menu = 'MENU_VIEW',
  Cart = 'CART_VIEW',
  User = 'USER_VIEW',
  Checkout = 'CHECKOUT_VIEW',
  PaymentMethod = 'PAYMENT_METHOD_VIEW',
}

type MODAL_VIEW = `${ModalView}`;
type SIDEBAR_VIEW = `${SidebarView}`;

export interface Context {
  // SEARCH
  displaySearch: boolean;
  openSearch: () => void;
  closeSearch: () => void;
  toggleSearch: () => void;

  // SIDEBAR
  displaySidebar: boolean;
  openSidebar: () => void;
  closeSidebar: () => void;
  toggleSidebar: () => void;
  closeSidebarIfPresent: () => void;
  sidebarView: SIDEBAR_VIEW;
  setSidebarView: (view: SIDEBAR_VIEW) => void;

  // MODAL
  displayModal: boolean;
  openModal: () => void;
  closeModal: () => void;
  modalView: MODAL_VIEW;
  setModalView: (view: MODAL_VIEW) => void;
}

const initialContext: Context = {
  // SEARCH
  displaySearch: false,
  openSearch: () => undefined,
  closeSearch: () => undefined,
  toggleSearch: () => undefined,

  // SIDEBAR
  displaySidebar: false,
  openSidebar: () => undefined,
  closeSidebar: () => undefined,
  toggleSidebar: () => undefined,
  closeSidebarIfPresent: () => undefined,
  sidebarView: SidebarView.Menu,
  setSidebarView: () => undefined,

  // MODAL
  displayModal: false,
  openModal: () => undefined,
  closeModal: () => undefined,
  modalView: ModalView.SignUp,
  setModalView: () => undefined,
};

type Action =
  | {
      type: 'OPEN_SEARCH';
    }
  | {
      type: 'CLOSE_SEARCH';
    }
  | {
      type: 'OPEN_SIDEBAR';
    }
  | {
      type: 'CLOSE_SIDEBAR';
    }
  | {
      type: 'OPEN_MODAL';
    }
  | {
      type: 'CLOSE_MODAL';
    }
  | {
      type: 'SET_MODAL_VIEW';
      payload: MODAL_VIEW;
    }
  | {
      type: 'SET_SIDEBAR_VIEW';
      payload: SIDEBAR_VIEW;
    };

export const UIContext = React.createContext<Context>(initialContext);

UIContext.displayName = 'UIContext';

function uiReducer(state: Context, action: Action) {
  switch (action.type) {
    case 'OPEN_SEARCH': {
      return {
        ...state,
        displaySearch: true,
        displaySidebar: false,
        displayModal: false,
      };
    }
    case 'CLOSE_SEARCH': {
      return {
        ...state,
        displaySearch: false,
      };
    }
    case 'OPEN_SIDEBAR': {
      return {
        ...state,
        displaySidebar: true,
        displaySearch: false,
        displayModal: false,
      };
    }
    case 'CLOSE_SIDEBAR': {
      return {
        ...state,
        displaySidebar: false,
      };
    }
    case 'OPEN_MODAL': {
      return {
        ...state,
        displayModal: true,
        displaySidebar: false,
        displaySearch: false,
      };
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        displayModal: false,
      };
    }
    case 'SET_MODAL_VIEW': {
      return {
        ...state,
        modalView: action.payload,
      };
    }
    case 'SET_SIDEBAR_VIEW': {
      return {
        ...state,
        sidebarView: action.payload,
      };
    }
  }
}

interface UIProviderProps {
  children: React.ReactNode;
}

export const UIProvider = ({ children }: UIProviderProps): JSX.Element => {
  const [state, dispatch] = React.useReducer(uiReducer, initialContext);

  /**
   * Search Actions
   */
  const openSearch = useCallback(
    () => dispatch({ type: 'OPEN_SEARCH' }),
    [dispatch]
  );
  const closeSearch = useCallback(
    () => dispatch({ type: 'CLOSE_SEARCH' }),
    [dispatch]
  );
  const toggleSearch = useCallback(
    () =>
      state.displaySearch
        ? dispatch({ type: 'CLOSE_SEARCH' })
        : dispatch({ type: 'OPEN_SEARCH' }),
    [dispatch, state.displaySearch]
  );

  /**
   * Sidebar Actions
   */
  const openSidebar = useCallback(
    () => dispatch({ type: 'OPEN_SIDEBAR' }),
    [dispatch]
  );
  const closeSidebar = useCallback(
    () => dispatch({ type: 'CLOSE_SIDEBAR' }),
    [dispatch]
  );
  const toggleSidebar = useCallback(
    () =>
      state.displaySidebar
        ? dispatch({ type: 'CLOSE_SIDEBAR' })
        : dispatch({ type: 'OPEN_SIDEBAR' }),
    [dispatch, state.displaySidebar]
  );
  const closeSidebarIfPresent = useCallback(
    () => state.displaySidebar && dispatch({ type: 'CLOSE_SIDEBAR' }),
    [dispatch, state.displaySidebar]
  );
  const setSidebarView = useCallback(
    (view: SIDEBAR_VIEW) =>
      dispatch({ type: 'SET_SIDEBAR_VIEW', payload: view }),
    [dispatch]
  );

  /**
   * Modal Actions
   */
  const openModal = useCallback(
    () => dispatch({ type: 'OPEN_MODAL' }),
    [dispatch]
  );
  const closeModal = useCallback(
    () => dispatch({ type: 'CLOSE_MODAL' }),
    [dispatch]
  );
  const setModalView = useCallback(
    (view: MODAL_VIEW) => dispatch({ type: 'SET_MODAL_VIEW', payload: view }),
    [dispatch]
  );

  const value: Context = useMemo(
    () => ({
      ...state,
      openSearch,
      closeSearch,
      toggleSearch,
      openSidebar,
      closeSidebar,
      toggleSidebar,
      closeSidebarIfPresent,
      openModal,
      closeModal,
      setModalView,
      setSidebarView,
    }),
    [
      state,
      // Search
      openSearch,
      closeSearch,
      toggleSearch,
      // Sidebar
      openSidebar,
      closeSidebar,
      toggleSidebar,
      closeSidebarIfPresent,
      setSidebarView,
      // Modal
      openModal,
      closeModal,
      setModalView,
    ]
  );

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};

export const useUI = () => {
  const context = React.useContext(UIContext);
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`);
  }
  return context;
};
