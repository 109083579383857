import {
  createRef,
  FocusEvent,
  KeyboardEvent,
  memo,
  useCallback,
  useEffect,
} from 'react';
import { SrOnly } from '@components/common/SrOnly';
import { useUI } from '@components/ui';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import { SearchIcon } from '@vibre/icons';
import { styled } from '@vibre/stitches';

interface Props {
  id?: string;
}

const Container = styled('div', {
  '$$search-height': 'calc($$height - $sizes$2)',
  '$$search-background': '$colors$transparent',
  '$$search-color': '$colors$accent9',

  'position': 'relative',
  'height': '$$search-height',
  'width': '$full',
  // '@lg': {
  //   '$$search-height': 'calc($sizes$header-desktop-inner - $sizes$2)',
  // },
});

const Input = styled('input', {
  'width': '$full',
  'height': '$full',
  'background': '$$search-background',
  'padding': '0 $$search-height 0 $2',
  'border': '$borderWidths$1 solid $$search-color',
  'appearance': 'none',

  '&:focus': {
    outline: 'none',
  },
});

const Button = styled('button', {
  'position': 'absolute',
  'background': '$$search-background',
  'right': '0',
  'top': '0',
  'size': '1em',
  'display': 'flex',
  'justifyContent': 'center',
  'alignItems': 'center',
  'fontSize': '$$search-height',
  'color': '$$search-color',

  '& > svg': {
    fontSize: 'calc($$search-height * 0.5)',
  },
});

const Searchbar = ({ id = 'search' }: Props): JSX.Element => {
  const router = useRouter();
  const inputRef = createRef<HTMLInputElement>();
  const containerRef = createRef<HTMLDivElement>();
  const { closeSearch } = useUI();
  const { t } = useTranslation();

  useEffect(() => {
    router.prefetch('/search');
  }, [router]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  const submitSearch = useCallback(() => {
    const q = inputRef.current?.value;
    if (q !== router.query.q) {
      router.push(
        {
          pathname: `/search`,
          query: q ? { q } : {},
        },
        undefined,
        { shallow: true }
      );
    }
  }, [inputRef, router]);

  const handleKeyUp = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') submitSearch();
      if (event.key === 'Escape') closeSearch();
    },
    [closeSearch, submitSearch]
  );

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      event.preventDefault();

      if (!containerRef.current?.contains(event.relatedTarget)) {
        closeSearch();
      } else {
        inputRef.current?.focus();
      }
    },
    [closeSearch, containerRef, inputRef]
  );

  return (
    <Container ref={containerRef}>
      <SrOnly as='label' htmlFor={id}>
        {t('search.title')}
      </SrOnly>

      <Input
        id={id}
        ref={inputRef}
        defaultValue={router.query.q}
        onKeyUp={handleKeyUp}
        onBlur={handleBlur}
      />

      <Button onClick={submitSearch}>
        <SearchIcon />
      </Button>
    </Container>
  );
};

export default memo(Searchbar);
