import React, { FC } from 'react';
import { UserNav } from '@components/common';
import { ChevronLeft, Cross } from '@components/icons';
import cn from 'clsx';
import useTranslation from 'next-translate/useTranslation';

import s from './SidebarLayout.module.css';

type ComponentProps = { className?: string } & (
  | { handleClose: () => any; handleBack?: never }
  | { handleBack: () => any; handleClose?: never }
);

const SidebarLayout: FC<ComponentProps> = ({
  children,
  className,
  handleClose,
  handleBack,
}) => {
  const { t } = useTranslation();
  return (
    <div className={cn(s.root, className)}>
      <header className={s.header}>
        {handleClose && (
          <button
            onClick={handleClose}
            aria-label='Close'
            className='h-header-mobile-inner lg:h-header-desktop-inner hover:text-accent-5 transition ease-in-out duration-150 flex items-center focus:outline-none mr-6'
          >
            <Cross className='h-6 w-6 hover:text-accent-3' />
            <span className='ml-2 text-accent-7 text-sm '>
              {t('global.close')}
            </span>
          </button>
        )}
        {handleBack && (
          <button
            onClick={handleBack}
            aria-label={t('global.go_back')}
            className='h-header-mobile-inner lg:h-header-desktop-inner hover:text-accent-5 transition ease-in-out duration-150 flex items-center focus:outline-none'
          >
            <ChevronLeft className='h-6 w-6 hover:text-accent-3' />
            <span className='ml-2 text-accent-7 text-xs'>
              {t('global.back')}
            </span>
          </button>
        )}
        <span className='h-header-mobile-inner lg:h-header-desktop-inner flex items-center'>
          <UserNav />
        </span>
      </header>
      <div className={s.container}>{children}</div>
    </div>
  );
};

export default SidebarLayout;
