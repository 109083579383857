import { KeyboardEvent, MouseEvent, useCallback } from 'react';
import { Avatar } from '@components/common';
import { useUI } from '@components/ui/context';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import type { LineItem } from '@vercel/commerce/types/cart';
import useCart from '@vercel/commerce-shopify/cart/use-cart';
import useCustomer from '@vercel/commerce-shopify/customer/use-customer';
import { BagIcon, HeartIcon, MenuIcon, SearchIcon } from '@vibre/icons';
import { styled } from '@vibre/stitches';

const countItem = (count: number, item: LineItem) => count + item.quantity;

const UserNavContainer = styled('nav', {
  display: 'flex',
  alignItems: 'center',
  height: '$full',
});

const UserNavButton = styled('button', {
  '$$height': '$sizes$header-mobile-inner',
  'display': 'inline-flex',
  'justifyContent': 'center',
  'alignItems': 'center',
  'cursor': 'pointer',
  'position': 'relative',
  'fontSize': 'calc($$height * 0.6)',
  'minWidth': '$5',
  'height': '1em',
  'color': '$text-base',

  '@lg': {
    $$height: '$sizes$header-desktop-inner',
  },

  '&:not(:first-child)': {
    marginLeft: '$5',
  },

  '&, &:focus, &:active': {
    outline: 'none',
  },

  'svg': {
    transition: 'transform $fast',
  },

  '&:hover': {
    color: '$accent6',
  },

  '&:hover svg': {
    scale: '120%',
  },
});

const CartCount = styled('span', {
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '$1',
  lineHeight: '$1',
  px: '2.5px',
  minWidth: '$5',
  position: 'absolute',
  fontWeight: '$bold',
  top: '$4',
  right: '$3',
  backgroundColor: '$secondary',
  color: '$primary',
  border: '1px solid currentColor',
  borderRadius: '$full',
});

const UserNav = (): JSX.Element => {
  const { data } = useCart();
  const { data: customer } = useCustomer();

  const {
    closeSidebarIfPresent,
    openModal,
    setSidebarView,
    setModalView,
    openSearch,
    closeSearch,
    displaySearch,
    openSidebar,
  } = useUI();
  const itemsCount = data?.lineItems.reduce(countItem, 0) ?? 0;
  const { t } = useTranslation();
  const router = useRouter();

  /**
   * Handles the click event on the user nav button.
   * (KeyUp or MouseUp is too late to prevent close if search is already shown)
   */
  const handleSearchClick = useCallback(
    (e: KeyboardEvent<HTMLButtonElement> | MouseEvent<HTMLButtonElement>) => {
      const event = e as
        | { type: 'keydown'; key: string }
        | { type: 'mousedown' };
      if (
        (event.type === 'keydown' && event.key === 'Enter') ||
        event.type === 'mousedown'
      ) {
        return displaySearch ? closeSearch() : openSearch();
      }
    },
    [displaySearch, closeSearch, openSearch]
  );

  /**
   *
   */
  const handleCartClick = useCallback(() => {
    closeSidebarIfPresent();
    setSidebarView('CART_VIEW');
    openSidebar();
  }, [closeSidebarIfPresent, setSidebarView, openSidebar]);

  /**
   *
   */
  const handleWishlistClick = useCallback(() => {
    closeSidebarIfPresent();
    router.push('/wishlist');
    // setSidebarView('WISHLIST_VIEW');
    // openSidebar();
  }, [closeSidebarIfPresent, router]);

  /**
   *
   */
  const handleProfileClick = useCallback(() => {
    if (customer) {
      closeSidebarIfPresent();
      setSidebarView('USER_VIEW');
      openSidebar();
    } else {
      setModalView('LOGIN_VIEW');
      openModal();
    }
  }, [
    closeSidebarIfPresent,
    customer,
    openModal,
    openSidebar,
    setModalView,
    setSidebarView,
  ]);

  /**
   *
   */
  const handleMenuClick = useCallback(() => {
    closeSidebarIfPresent();
    setSidebarView('MENU_VIEW');
    openSidebar();
  }, [closeSidebarIfPresent, setSidebarView, openSidebar]);

  return (
    <UserNavContainer>
      <UserNavButton
        onMouseDown={handleSearchClick}
        onKeyDown={handleSearchClick}
        aria-label={t('search.title')}
      >
        <SearchIcon />
      </UserNavButton>

      <UserNavButton
        onClick={handleCartClick}
        aria-label={`${t('cart.title')} - ${t('cart.items', {
          count: itemsCount,
        })}`}
      >
        <BagIcon />
        {itemsCount > 0 && <CartCount>{itemsCount}</CartCount>}
      </UserNavButton>

      <UserNavButton
        onClick={handleWishlistClick}
        aria-label={t('wishlist.title')}
      >
        <HeartIcon />
      </UserNavButton>

      <UserNavButton
        onClick={handleProfileClick}
        aria-label={t('profile.title')}
      >
        <Avatar isLoggedIn={customer} />
      </UserNavButton>

      <UserNavButton
        onClick={handleMenuClick}
        aria-label={t('global.menu_show')}
      >
        <MenuIcon />
      </UserNavButton>
    </UserNavContainer>
  );
};

export default UserNav;
