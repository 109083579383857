import { useState } from 'react';

import { AcidSmileyIcon } from '@vibre/icons';
import { styled } from '@vibre/stitches';

const AvatarIcon = styled(AcidSmileyIcon, {
  display: 'inline-block',

  variants: {
    style: {
      'logged-out-default': {
        'color': '$secondary',
        'fill': '$transparent',
        '& path:first-of-type': {
          color: '$secondary',
        },
      },
      'logged-out-active': {
        'color': '$primary',
        'fill': '$secondary',
        '& path:first-of-type': {
          color: '$secondary',
        },
      },
      'logged-in-default': {
        'color': '$primary',
        'fill': '$vibrant-yellow',
        '& path:first-of-type': {
          color: '$vibrant-yellow',
        },
      },
      'logged-in-active': {
        'color': '$vibrant-yellow',
        'fill': '$transparent',
        '& path:first-of-type': {
          color: '$vibrant-yellow',
        },
      },
    },
  },
});

const Avatar = ({ isLoggedIn = false }): JSX.Element => {
  const [variant, setVariant] = useState<'default' | 'active'>('default');

  return (
    <AvatarIcon
      style={`logged-${isLoggedIn ? 'in' : 'out'}-${variant}`}
      onMouseEnter={() => setVariant('active')}
      onMouseLeave={() => setVariant('default')}
      onFocusCapture={() => setVariant('active')}
      onBlurCapture={() => setVariant('default')}
    />
  );
};

export default Avatar;
