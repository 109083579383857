import { useMemo } from 'react';
import { Searchbar, UserNav } from '@components/common';
import { Container, useUI } from '@components/ui';
import { Logo } from '@mdlr/components/Logo';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { styled } from '@vibre/stitches';
import s from './Navbar.module.css';
import NavbarRoot from './NavbarRoot';

interface Link {
  href: string;
  label: string;
}

interface NavbarProps {
  links?: Link[];
}

const NavMenu = styled('nav', {
  'display': 'none',
  'height': '$$height',

  '& > a': {
    'height': 'inherit',
    'whiteSpace': 'nowrap',
    'display': 'inline-flex',
    'alignItems': 'center',
    'cursor': 'pointer',
    'color': '$accent5',
    'fontWeight': '550',

    '&:not(:first-of-type)': {
      ml: '$3',
    },

    '&:hover, &[aria-current="true"]': {
      color: '$accent9',
    },
  },

  '@head': {
    display: 'flex',
    flexDirection: 'row',
  },
});

const Centered = styled('div', {
  display: 'flex',
  flexGrow: 1,
  height: '$$height',
  alignItems: 'center',
  px: '$6',
});

const Navbar = ({ links = [] }: NavbarProps): JSX.Element => {
  const { asPath } = useRouter();
  const { displaySearch } = useUI();

  const navLinks = useMemo(
    () =>
      [
        {
          href: '/search',
          label: 'All',
        },
        ...links,
      ].map((link) => ({
        ...link,
        active: asPath.replace(/\/$/, '') === link.href.replace(/\/$/, ''),
      })),
    [asPath, links]
  );

  return (
    <NavbarRoot>
      <Container className='w-full'>
        <div className={s.nav}>
          <div className='flex items-center'>
            <Logo scaleOnHover />
          </div>
          <Centered>
            {displaySearch ? (
              <Searchbar />
            ) : (
              <NavMenu>
                {navLinks.map(({ label, href, active }) => (
                  <Link key={href} href={href}>
                    <a aria-current={active}>{label}</a>
                  </Link>
                ))}
              </NavMenu>
            )}
          </Centered>
          <div className='flex items-center justify-end space-x-8'>
            <UserNav />
          </div>
        </div>
        {/*<div className='flex pb-4 lg:px-6 lg:hidden'>*/}
        {/*  <Searchbar id='mobile-search' />*/}
        {/*</div>*/}
      </Container>
    </NavbarRoot>
  );
};

export default Navbar;
