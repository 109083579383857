import { FC } from 'react';
import { I18nWidget } from '@components/common';
import { Github, Vercel } from '@components/icons';
import { Container } from '@components/ui';
import getSlug from '@lib/get-slug';
import { Logo } from '@mdlr/components/Logo';
import cn from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import type { Page } from '@vercel/commerce/types/page';
import s from './Footer.module.css';

interface Props {
  className?: string;
  children?: any;
  pages?: Page[];
}

const links = [
  {
    name: 'Home',
    url: '/',
  },
];

const Footer: FC<Props> = ({ className, pages }) => {
  const { sitePages } = usePages(pages);
  const rootClassName = cn(s.root, className);

  const { t } = useTranslation('common');

  return (
    <footer className={rootClassName}>
      <Container>
        <div className='grid grid-cols-1 lg:grid-cols-12 gap-8 border-b border-accent-2 py-12 text-primary bg-primary transition-colors duration-150'>
          <div className='col-span-1 lg:col-span-2'>
            <Logo />
          </div>
          <div className='col-span-1 lg:col-span-8'>
            <div className='grid md:grid-rows-4 md:grid-cols-3 md:grid-flow-col'>
              {[...links, ...sitePages].map((page) => (
                <span key={page.url} className='py-3 md:py-0 md:pb-4'>
                  <Link href={page.url!}>
                    <a className='text-accent-9 hover:text-accent-6 transition ease-in-out duration-150'>
                      {page.name}
                    </a>
                  </Link>
                </span>
              ))}
            </div>
          </div>
          <div className='col-span-1 lg:col-span-2 flex items-start lg:justify-end text-primary'>
            <div className='flex space-x-6 items-center h-10'>
              <I18nWidget />
            </div>
          </div>
        </div>
        <div className='pt-6 pb-10 flex flex-col md:flex-row justify-between items-center space-y-4 text-accent-6 text-sm'>
          <div>
            <span>
              {t('global.copyright', {
                year: new Date().getFullYear(),
              })}
            </span>
          </div>
          <div className='flex items-center text-primary text-sm'>
            <span className='text-primary'>Created by</span>
            <a
              rel='noopener noreferrer'
              href='https://vercel.com'
              aria-label='Vercel.com Link'
              target='_blank'
              className='text-primary'
            >
              <Vercel
                className='inline-block h-6 ml-3 text-primary'
                alt='Vercel.com Logo'
              />
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
};

function usePages(pages?: Page[]) {
  const { locale } = useRouter();
  const sitePages: Page[] = [];

  if (pages) {
    pages.forEach((page) => {
      const slug = page.url && getSlug(page.url);
      if (!slug) return;
      if (locale && !slug.startsWith(`${locale}/`)) return;
      sitePages.push(page);
    });
  }

  return {
    sitePages: sitePages.sort(bySortOrder),
  };
}

// Sort pages by the sort order assigned in the BC dashboard
function bySortOrder(a: Page, b: Page) {
  return (a.sort_order ?? 0) - (b.sort_order ?? 0);
}

export default Footer;
