import React, { useCallback, useEffect } from 'react';
import { useUI } from '@components/ui';

export const KeyboardShortcuts = () => {
  const registered = React.useRef<boolean>(false);
  const { toggleSearch } = useUI();

  const handleShortcuts = useCallback(
    (event) => {
      if (event.metaKey && event.key === 'k') toggleSearch();
    },
    [toggleSearch]
  );

  useEffect(() => {
    if (!registered.current) {
      document.addEventListener('keydown', handleShortcuts);
      registered.current = true;
    }
    return () => {
      document.removeEventListener('keydown', handleShortcuts);
      registered.current = false;
    };
  }, [handleShortcuts]);

  return null;
};
