import { ReactNode, useEffect, useState } from 'react';
import throttle from 'lodash.throttle';

import { styled } from '@vibre/stitches';

interface NavbarRootProps {
  children: ReactNode;
}

const NavContainer = styled('div', {
  '$$height': '$sizes$header-mobile-inner',

  'position': 'sticky',
  'top': 0,
  'mt': '$1',
  'background': '$primary',
  'zIndex': '$navbar',
  'height': '$header-mobile',
  'display': 'flex',
  'flexDirection': 'column',
  'justifyContent': 'center',

  '@head': {
    $$height: '$sizes$header-desktop-inner',
    height: '$header-desktop',
  },

  'variants': {
    shadow: {
      true: {
        boxShadow: '$navbar',
      },
      false: {
        boxShadow: 'none',
      },
    },
  },
});

const NavbarRoot = ({ children }: NavbarRootProps): JSX.Element => {
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = throttle(() => {
      const offset = 0;
      const { scrollTop } = document.documentElement;
      const scrolled = scrollTop > offset;

      if (hasScrolled !== scrolled) {
        setHasScrolled(scrolled);
      }
    }, 200);

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [hasScrolled]);

  return <NavContainer shadow={hasScrolled}>{children}</NavContainer>;
};

export default NavbarRoot;
