
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { FC, useEffect } from 'react';
import { Head } from '@components/common';
import { UIProvider } from '@components/ui/context';
import { KeyboardShortcuts } from '@components/ui/KeyboardShortcuts';
// import { GlobalSettingsProvider } from '@mdlr/hooks/useGlobalSettings';
import type { AppProps } from 'next/app';

import '@assets/main.css';
import '@assets/chrome-bug.css';
import 'keen-slider/keen-slider.min.css';
import '@fontsource/inter/variable-full.css';
import '@fontsource/rubik/700.css';

const Noop: FC = ({ children }) => <>{children}</>;

const __Page_Next_Translate__ = function MyApp({ Component, pageProps }: AppProps) {
  const Layout = (Component as any).Layout || Noop;
  useEffect(() => {
    document.body.classList?.remove('loading');
  }, []);

  return (
    <>
      <Head />
      <UIProvider>
        <KeyboardShortcuts />
        <Layout pageProps={pageProps}>
          <Component {...pageProps} />
        </Layout>
      </UIProvider>
    </>
  );
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  