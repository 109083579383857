import React, { memo } from 'react';
import { useUI } from '@components/ui/context';
import cn from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import useLogout from '@vercel/commerce-shopify/auth/use-logout';
import SidebarLayout from '../SidebarLayout';
import s from './UserSidebarView.module.css';

const LINKS = [
  {
    name: 'wishlist.title',
    href: '/wishlist',
  },
  {
    name: 'orders.title',
    href: '/orders',
  },
  {
    name: 'profile.title',
    href: '/profile',
  },
  {
    name: 'cart.title',
    href: '/cart',
  },
];

const UserSidebarView = (): JSX.Element => {
  const logout = useLogout();
  const { pathname } = useRouter();
  const { closeSidebar } = useUI();
  const { t } = useTranslation();

  return (
    <SidebarLayout handleClose={closeSidebar}>
      <ul className={s.dropdownMenu}>
        {LINKS.map(({ name, href }) => (
          <li key={href}>
            <Link href={href}>
              <a
                className={cn(s.link, {
                  [s.active]: pathname === href,
                })}
                onClick={closeSidebar}
              >
                {t(`${name}`)}
              </a>
            </Link>
          </li>
        ))}
        {/*<li>*/}
        {/*  <a*/}
        {/*    className={cn(s.link, 'justify-between')}*/}
        {/*    onClick={() => {*/}
        {/*      theme === 'dark' ? setTheme('light') : setTheme('dark');*/}
        {/*      setDisplay(false);*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <div>*/}
        {/*      Theme: <strong>{theme}</strong>{' '}*/}
        {/*    </div>*/}
        {/*    <div className='ml-3'>*/}
        {/*      {theme == 'dark' ? (*/}
        {/*        <Moon width={20} height={20} />*/}
        {/*      ) : (*/}
        {/*        <Sun width='20' height={20} />*/}
        {/*      )}*/}
        {/*    </div>*/}
        {/*  </a>*/}
        {/*</li>*/}
        <li>
          <a
            className={cn(s.link, 'border-t border-accent-2 mt-4')}
            onClick={() => logout()}
          >
            {t('profile.logout')}
          </a>
        </li>
      </ul>
    </SidebarLayout>
  );
};

export default memo(UserSidebarView);
