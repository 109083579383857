module.exports = {
  /**
   * create a fake local as default to allow rendering folders for each language
   *    --> https://github.com/vercel/next.js/discussions/18419
   *    --> https://nextjs.org/docs/advanced-features/i18n-routing#prefixing-the-default-locale
   */
  locales: ['default', 'en', 'de'],
  localesToIgnore: ['default'],
  defaultLocale: 'default',
  localeDetection: false,
  keySeparator: '.',

  /**
    language definitions
   */
  defaultNS: 'common',
  pages: {
    '*': ['common'],
  },

  loadLocaleFrom: (lang, ns) =>
    import(`./locales/${lang}.json`).then((m) => m.default),
};
