import { FC, useState } from 'react';
import { ChevronUp, Cross } from '@components/icons';
import ClickOutside from '@lib/click-outside';
import cn from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import s from './I18nWidget.module.css';

const LOCALES_MAP = {
  en: {
    name: 'English',
    img: {
      filename: 'gb.svg',
      alt: 'GB Flag',
    },
  },
  de: {
    name: 'German',
    img: {
      filename: 'de.svg',
      alt: 'DE Flag',
    },
  },
};

const I18nWidget: FC = () => {
  const [display, setDisplay] = useState(false);
  const { locale, locales, asPath: currentPath } = useRouter();
  const options = locales?.filter((val) => val !== 'default');
  const currentLocale = locale === 'default' ? 'en' : locale;

  return (
    <ClickOutside active={display} onClick={() => setDisplay(false)}>
      <nav className={s.root}>
        <div
          className='flex items-center relative'
          onClick={() => setDisplay(!display)}
        >
          <button className={s.button} aria-label='Language selector'>
            <Image
              width='20'
              height='20'
              className='block mr-2 w-5 rounded-full'
              src={`/flags/${LOCALES_MAP[`${currentLocale}`].img.filename}`}
              alt={LOCALES_MAP[`${currentLocale}`].img.alt}
            />
            {options && (
              <span className='cursor-pointer'>
                <ChevronUp className={cn(s.icon, { [s.active]: !display })} />
              </span>
            )}
          </button>
        </div>
        <div className='absolute top-0 right-0'>
          {options?.length && display ? (
            <div className={s.dropdownMenu}>
              <div className='flex flex-row justify-end px-6'>
                <button
                  onClick={() => setDisplay(false)}
                  aria-label='Close panel'
                  className={s.closeButton}
                >
                  <Cross className='h-6 w-6' />
                </button>
              </div>
              <ul>
                {options.map((locale) => (
                  <li key={locale}>
                    <Link href={currentPath} locale={locale}>
                      <a
                        className={cn(s.item)}
                        onClick={() => setDisplay(false)}
                      >
                        {LOCALES_MAP[`${locale}`].name}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </nav>
    </ClickOutside>
  );
};

export default I18nWidget;
