import React, { createRef, ReactNode, useEffect } from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

import s from './Sidebar.module.css';

interface SidebarProps {
  children: ReactNode;
  onClose: () => void;
}

const Sidebar = ({ children, onClose }: SidebarProps): JSX.Element => {
  const sidebarRef = createRef<HTMLDivElement>();
  const contentRef = createRef<HTMLDivElement>();

  const onKeyDownSidebar = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Escape') onClose();
  };

  useEffect(() => {
    sidebarRef.current?.focus();

    if (contentRef.current) {
      disableBodyScroll(contentRef.current, { reserveScrollBarGap: true });
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [contentRef, sidebarRef]);

  return (
    <div
      className={s.root}
      ref={sidebarRef}
      onKeyDown={onKeyDownSidebar}
      tabIndex={1}
    >
      <div className='absolute inset-0 overflow-hidden'>
        <div className={s.backdrop} onClick={onClose} />
        <section className='absolute inset-y-0 right-0 w-full md:w-auto max-w-full flex outline-none md:pl-10'>
          <div className='h-full w-full md:w-screen md:max-w-md'>
            <div className={s.sidebar} ref={contentRef}>
              {children}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Sidebar;
