import { SEO } from '@components/common';

import { FavIcon } from '@vibre/favicon';

const Head = (): JSX.Element => {
  return (
    <SEO>
      <meta
        key='viewport'
        name='viewport'
        content='width=device-width, initial-scale=1'
      />

      <FavIcon />
      <link rel='manifest' href='/site.webmanifest' key='site-manifest' />
    </SEO>
  );
};

export default Head;
